import React from "react";
import { accent } from "../utils/svgStyles";
import { COLORS } from "../gameConstants";

const Chili = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 26"
    style={{ display: "block" }}
  >
    <path
      d="M16.73,17.56c1.15,2.08,2.84,2.77,5.06,3.38S30,23,34.5,23.67a54.44,54.44,0,0,0,15.43-.08c3.84-.77,9.64-3.53,12.36-5.18a41.56,41.56,0,0,0,7.56-6.11C71.35,10.69,73,9,74.34,8.5s3.53-.15,4.11-.34a1.46,1.46,0,0,0,1-1.19c0-.38-1-1.42-2.15-2A8.09,8.09,0,0,0,73,4.17a20.1,20.1,0,0,0-6.18,2.38c-2.11,1.23-5.41,2.68-8.56,4.22a31.32,31.32,0,0,1-13.63,2.76A40.11,40.11,0,0,1,30,10.58c-4.91-2-6-3.34-8.1-3.3s-2.69,1-2.69,1l-1.19,2L16.88,12.5l-.42,2.18Z"
      style={{ ...accent, fill: COLORS.red }}
    />
    <path
      d="M16.55,18a.92.92,0,0,1-.64-.33,1.74,1.74,0,0,1-.33-1.41,2.75,2.75,0,0,0-.65-2.29,14.11,14.11,0,0,0-3.53-2.17l-.85-.44a55.37,55.37,0,0,1-7-4.54,6.32,6.32,0,0,1-2.17-3.5,2.2,2.2,0,0,1,.45-2,.72.72,0,0,1,.35-.11A2.78,2.78,0,0,1,4.08,2.4a1.61,1.61,0,0,1,.06.41,1.23,1.23,0,0,0,.13.54A16.65,16.65,0,0,0,7.38,5.78,64,64,0,0,0,13.62,9.1a6.44,6.44,0,0,0,2.45.61,2.22,2.22,0,0,0,.62-.08A2.81,2.81,0,0,0,18,8.73a2.22,2.22,0,0,0,.29-.42c.25-.4.42-.69.73-.75l.14,0a.74.74,0,0,1,.53.25A1.09,1.09,0,0,1,20,8.6a1.69,1.69,0,0,1-.45.85,3.62,3.62,0,0,0-.37.57s0,.05.08.24a1.32,1.32,0,0,1,.21.66c0,.63-.57,1-1.06,1.41-.13.09-.14.18-.1.52a2.2,2.2,0,0,1,0,.44,1.81,1.81,0,0,1-.79,1.27l-.08.08,0,.13a1.12,1.12,0,0,1,.25.86.94.94,0,0,1-.44.67,1.22,1.22,0,0,1,.32,1A1.12,1.12,0,0,1,16.55,18Z"
      style={{ ...accent, fill: COLORS.green }}
    />
  </svg>
);

export default Chili;
